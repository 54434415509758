<template>
    <div>
        <!-- 顶部 -->
        <el-row>
            <el-col :span='24'>
                <el-form :inline='true'>
                    <el-form-item label='启用标识:'>
                        <el-select v-model="filters.isEnable" placeholder="请选择">
                            <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <!-- 内容 -->
        <div class='main'>
            <!-- 左侧教材 -->
            <div class='left'>
                <el-tree
                class='treeEle'
                :data="data"
                :props="props"
                :load="loadNode"
                lazy
                ref='tree'
                node-key="ID"
                show-checkbox
                check-strictly
                highlight-current
                v-loading='listLoading'
                element-loading-target='document.querySelector(".treeEle")'
                check-on-click-node
                @check='handleCheck'
                @node-click='nodeClick'>
                    <span slot-scope="{node}">
                        <i class='el-icon-notebook-1'></i>
                        <i v-if="node.label.DataType == 0" style='font-style:normal;color:#FF0000!important;'>{{node.label.Name}}</i>
                        <i v-if="node.label.DataType == 1" style='font-style:normal;'>{{node.label.Name}}</i>
                        <i v-if="node.label.DataType == 2" style='font-style:normal;color:#0000FF!important;'>{{node.label.Name}}</i>
                    </span>
                </el-tree>
            </div>
            <div class='right' v-if='tableData.length'>
                <el-table :data="tableData" border v-loading="listLoading1" class='tableEle' element-loading-target='document.querySelector(".tableEle")'>
                    <el-table-column prop="QuestionsCode" label="试题编号" width="" align='center'></el-table-column>
                    <el-table-column prop="QuestionsType" label="试题类型" width="" align='center'>
                        <template slot-scope='scope'>
                            <el-tag v-if='scope.row.QuestionsType == 0' type='primary'>单选题</el-tag>
                            <el-tag v-if='scope.row.QuestionsType == 1' type='warning'>多选题</el-tag>
                            <el-tag v-if='scope.row.QuestionsType == 2' type='danger'>判断题</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="Difficulty" label="试题难度" width="" align='center'>
                        <template slot-scope='scope'>
                            <el-tag v-if='scope.row.Difficulty == 0' type='danger'>高</el-tag>
                            <el-tag v-if='scope.row.Difficulty == 1' type='warning'>中</el-tag>
                            <el-tag v-if='scope.row.Difficulty == 2' type='primary'>低</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="QuestionsTitle" label="试题题目" width="" align='center'></el-table-column>
                </el-table>
            </div>
        </div>
        <!-- 新增/编辑 -->
        <el-dialog
        :title="isAdd?'新增':'编辑'"
        :visible.sync="dialogVisible"
        :close-on-click-modal='false'
        width="50%">
        <el-form :model="form" :rules="rules" ref="formRef" label-width="100px">
            <el-form-item label="排序序号:" prop="sortNo">
                <el-input v-model="form.sortNo" @input='() => (form.sortNo = form.sortNo.replace(/\s/g,""))'></el-input>
            </el-form-item>
            <el-form-item label="教材名称:" prop="name" v-if='isAdd == true && !node.level'>
                <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="appID:" prop="Isappinfor" v-if='(isAdd == true && !node) || node.level == 1'>
                <el-select v-model="form.Isappinfor" placeholder="请选择appID">
                    <el-option
                        v-for="item in IsappinforArr"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="项目ID:" prop="ProjectSourceID" v-if='(isAdd == true && !node) || node.level == 1'>
                <el-input v-model="form.ProjectSourceID" placeholder="请输入项目ID"></el-input>
            </el-form-item>
            <el-form-item label="教材名称:" prop="name" v-if='isAdd == false && node.level == 1'>
                <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="章目录名称:" prop="name" v-if='(isAdd == true && node.level == 1) || (isAdd == false && node.level == 2)'>
                <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="节目录名称:" prop="name" v-if='(isAdd == true && node.level == 2) || (isAdd == false && node.level == 3)'>
                <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label='数据类型:' prop='dataType' v-if='(this.isAdd == false && node.level == 1) || !node.level'>
                <el-select v-model="form.dataType" placeholder="请选择" @change='handleIsStationChange' :disabled='editDisabled'>
                    <el-option
                        v-for="item in options1"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <!--  -->
            <el-form-item :label='form.dataType == 2 ? "选择行业:" : form.dataType == 1 ? "选择岗位:" : ""' prop='dataDictionaryID' v-if='form.dataType != 0'>
                <el-select v-model="form.dataDictionaryID" placeholder="请选择">
                    <el-option
                        v-for="item in options4"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <!--  -->
            <el-form-item label='字体颜色:' prop='fontColor' v-if='isGangWei'>
                <el-color-picker v-model="form.fontColor"></el-color-picker>
            </el-form-item>
            <el-form-item label='是否精品:' prop='isElite' v-if='isGangWei'>
                <el-switch v-model="form.isElite" active-color="#13ce66" inactive-color="#dcdfe6"></el-switch>
            </el-form-item>
            <el-form-item label='学习费用:' prop='learningAmount' v-if='isGangWei'>
                <el-input v-model='form.learningAmount' type='number' @input='() =>(form.learningAmount = form.learningAmount.replace(/^-/ ,""))'></el-input>
            </el-form-item>
            <el-form-item label='教材图片:' prop='icon' v-if='isGangWei'>
                <el-upload
                :action="action"
                :headers='headers'
                :file-list="fileList"
                :on-success='uploadSuccess'
                :on-error='uploadError'
                :on-remove='uploadRemove'
                :before-upload="beforeUpload"
                list-type="picture">
                <el-button size="small" type="primary" v-if='!form.icon'>点击上传</el-button>
                <div slot="tip" class="el-upload__tip">上传文件不能超过2MB</div>
                </el-upload>
            </el-form-item>         
            <el-form-item label='备注:' prop='remarks'>
                <el-input v-model='form.remarks' type='textarea'></el-input>
            </el-form-item>
            <el-form-item label='启用标识:' prop='isEnable'>
                <el-switch v-model="form.isEnable" active-color="#13ce66" inactive-color="#dcdfe6"></el-switch>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="submit" :loading='formLoading'>确 定</el-button>
        </span>
        </el-dialog>
        <!-- 关联试题 -->
        <el-dialog
        title="关联试题"
        :visible.sync="relationQuestionsDialog"
        width="70%"
        :close-on-click-modal='false'
        @close='reload()'>
        <div>
            <!-- <RelationQuestion :teachingMaterialInfoID='selectID' @getQuestionArr='getQuestionArr' :selRelationData='selRelationData'></RelationQuestion> -->
            <!-- 顶部 -->
            <el-row>
                <el-col :span='24'>
                    <el-form :inline='true'>
                        <el-form-item label='关键字:'>
                            <el-input v-model='filters1.questionsTitle' placeholder='请输入题目' clearable @clear='handleSearch' @input='() =>(filters1.questionsTitle = filters1.questionsTitle.replace(/\s/g,""))' @keyup.enter.native='handleSearch'></el-input>
                        </el-form-item> 
                        <el-form-item label='试题类型:'>
                            <el-select v-model="filters1.questionsType" placeholder="请选择" clearable @change='handleSearch'>
                                <el-option
                                v-for="item in options2"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label='是否关联:'>
                            <el-select v-model="filters1.rangeType" placeholder="请选择" clearable @change='handleSearch'>
                                <el-option
                                v-for="item in options3"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-button @click='handleSearch' type='primary'>查询</el-button>
                        </el-form-item>
                    </el-form>
                </el-col>
            </el-row>
            <!-- 列表 -->
            <el-table
            border
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%;">
                <el-table-column width='55'>
                    <template scope='scope'>
                        <el-checkbox v-model='relationQuestionArr' :label='scope.row.ID'>{{scope.row.IDs}}</el-checkbox>
                    </template>
                </el-table-column>
                <el-table-column prop='QuestionsCode' width="120" align='center' label='试题编号'></el-table-column>
                <el-table-column prop='QuestionsType' width="180" align='center' label='试题类型'>
                    <template slot-scope='scope'>
                        <el-tag v-if='scope.row.QuestionsType == 0' type='primary'>单选题</el-tag>
                        <el-tag v-if='scope.row.QuestionsType == 1' type='warning'>多选题</el-tag>
                        <el-tag v-if='scope.row.QuestionsType == 2' type='danger'>判断题</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop='Difficulty' width="180" align='center' label='试题难度'>
                    <template slot-scope='scope'>
                        <el-tag v-if='scope.row.Difficulty == 0' type='danger'>高</el-tag>
                        <el-tag v-if='scope.row.Difficulty == 1' type='warning'>中</el-tag>
                        <el-tag v-if='scope.row.Difficulty == 2' type='primary'>低</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop='QuestionsTitle' width="" align='center' label='试题题目'></el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pages.pageIndex"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pages.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pages.dataCount">
            </el-pagination>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="relationQuestionsDialog = false">取 消</el-button>
            <el-button type="primary" @click="relationQuestionSubmit" :loading='relationQuestionsLoading'>确 定</el-button>
        </span>
        </el-dialog>
    </div>
</template>

<script>
import { getButtonList } from "../../promissionRouter";
import Toolbar from '../../components/Toolbar.vue';
import Qs from 'qs';
import api from '@/api'
import {
    getTeachingMaterialTree,
    addTeachingMaterialInfo,
    updateTeachingMaterialInfo,
    deleteTeachingMaterialInfo,
    relationTeachingExamination,
    getExaminationQuestionsByTMIDListPageAsync,
    getDataDictionaryList
} from '@/api/api'
export default {
    inject:['reload'],
    components:{Toolbar},
    data(){
        return {
            buttonList:[],
            filters:{
                keyWord:'',
                isEnable:''
            },
            filters1:{
                questionsTitle:'',
                questionsType:'',
                rangeType:0
            },
            options:[
                {label:'启用',value:true},
                {label:'禁用',value:false}
            ],
            // 数据类型
            options1:[
                {label:'基础',value:0},
                {label:'岗位',value:1},
                {label:'行业',value:2}
            ],
            options2:[
                {label:'单选题',value:0},
                {label:'多选题',value:1},
                {label:'判断题',value:2}
            ],
            options3:[
                {label:'全部',value:0},
                {label:'已关联',value:1},
                {label:'未关联',value:2}
            ],
            IsappinforArr:[
                {label:'52',value: 52},
                {label:'53',value: 53},
            ],
            options4:[],
            tableData:[],
            pages:{
                pageIndex:1,
                pageSize:20,
                dataCount:0
            },




            isAdd:true,
            dialogVisible:false,
            formLoading:false,
            form:{
                sortNo:'',
                name:'',
                learningAmount:0,
                remarks:'',
                isEnable:true,
                icon:'', 
                isElite:true,
                dataType:'',
                fontColor:null,
                dataDictionaryID:'',
                Isappinfor: "",
                ProjectSourceID: "",
            },
            rules:{
                parentId:[{}],
                sortNo:[{required:true,message:'请输入序号',trigger:'blur'}],
                name:[{required:true,message:'请输入节点名称',trigger:'blur'}],
                remarks:[{}],
                icon:[{}],
                dataType:[{required:true,message:'请选择数据类型',trigger:'change'}],
                fontColor:[{}],
                dataDictionaryID:[{required:true,message:'请选择',trigger:'change'}],
                Isappinfor:[{required:true,message:'请选择appID',trigger:'change'}],
                ProjectSourceID:[{required:true,message:'请选择项目ID',trigger:'change'}],
            },

            // 图片上传相关数据
            action:'',
            fileList:[], // 图片集合
            tempImg:'',


            // 树形控件相关数据
            props:{
                label:function(data) {
                    return {
                        DataType: data.DataType,
                        Name: data.Name
                    }
                },
                children:'Children',
                // isLeaf:'HasChildren'
            },
            data:[],
            needData:[],
            listLoading:false,
            node: "", // 节点的node信息
            resolve:'',
            selectID:null, // 选中的教材ID
            selectData:{}, // 选中的数据对象
            isNotAdd:false, // 教材只能添加到3级
            treeUrl:0, // 级数


            // 关联试题相关数据
            relationQuestionsDialog:false,
            relationQuestionsLoading:false,
            relationQuestionArr:[], //关联的试题集合
            listLoading1:false,

            selRelationData:[],
            isGangWei:true, // 某些字段是否展示
            editDisabled:false, // 编辑时数据类型禁填
        }
    },
    computed:{
        headers(){
            return {
                "Authorization": 'Bearer ' + localStorage.getItem('Token')
            }
        },
    },
    watch:{
        selectID(newVal){
            if(newVal){
                var params = {
                    teachingMaterialID:this.selectID,
                    rangeType:1
                }
                getExaminationQuestionsByTMIDListPageAsync(params).then(res => {
                    if(res.data.Success){
                        this.relationQuestionArr = res.data.Response.Data.map(item => {
                            return item.ID
                        })                                      
                    }else{
                        return
                    }
                }).catch(() =>{})
            }
        },
        'form.dataType'(newVal){
            if(newVal != 0){
                var params = {dataType:newVal}         
                getDataDictionaryList(params).then(res => {
                    if(res.data.Success){
                        var data = res.data.Response
                        this.options4 = data.map(item => ({
                            label:item.Name,
                            value:item.ID
                        }))
                    }else{}
                }).catch(() => {})
            }
        }
    },
    methods:{
        callFunction(item) {
            this[item.Func].apply(this, item);
        },
        loadNode(node,resolve){
            this.resolve = resolve
            this.listLoading = true
            var params = {}
            if(sessionStorage.getItem('isEnable') == 1){
                params.isEnable = true
            }else if(sessionStorage.getItem('isEnable') == 2){
                params.isEnable = false
            }
            if(node.data){
                params.parentId = node.data.ID
            }else{
                params.parentId = 0
            }
            getTeachingMaterialTree(params).then(res => {
                this.listLoading = false
                if(res.data.Success){
                    var data = res.data.Response
                    this.needData = [...data]
                    resolve(data)
                }else{
                    this.$message.error(res.data.Message)
                }
            }).catch(() =>{this.listLoading = false})
        },
        handleCheck(data,checked){
            if(checked.checkedKeys.length){
                this.selectID = data.ID
                this.selectData = {...data}
                this.$refs['tree'].setCheckedKeys([data.ID],true)  // 设置一次只能选择一个节点
            }else{
                this.selectID = 0
            }
        },
        nodeClick(data,node){
            this.node = node
            // 设置节点是否展示相应的表单项(新增时)
            if(node.level == 1 && node.checked == true){
                this.isGangWei = false
            }else if(node.level > 1 && node.checked == true){
                this.isGangWei = false
            }           

            // 获取treeurl
            var arr = []
            arr.push(node.data.ID) // 教材的id
            if(node.parent && node.data.ID){
                var  secondParent = node.parent
                arr.push(secondParent.data.ID) // 教材的父id
                if(secondParent.parent && secondParent.data.ID){
                    var firstParent = secondParent.parent
                    arr.push(firstParent.data.ID) // 教材终极id
                }
            }           
            arr = arr.reverse().join(',')
            if(node.level == 3){
                arr = ',' + arr
            }
            this.treeUrl = arr
            
            // 设置只能添加到3级
            if(node.level == 3 && node.checked == true){
                this.isNotAdd = true
            }else{
                this.isNotAdd = false
            }
        },
        // 图片上传成功
        uploadSuccess(res,file,fileList){
            if(res.Success){
                this.tempImg = res.Response.webupurl
                this.img = res.Response.weburl
                this.form.icon = res.Response.webupurl
                this.$message.success(res.Message)
                this.tempImg = ''
                this.img = ''  
                this.fileList = fileList  
                
                this.a = true //////////////////////
            }else{
                this.$message.error(res.Message)
            }
        },
        // 图片上传失败
        uploadError(err,file,fileList){
            this.$message.error('上传失败')
        },
        // 图片上传限制
        beforeUpload(file){
            const isLt2M = file.size / 1024 / 1024 < 2;
            if(!isLt2M){
                this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            return isLt2M
        },
        // 图片移除
        uploadRemove(file,fileList){
            this.form.icon = ''
        },


        // 查询
        getTeachingMaterialTree(){
            this.reload()
            this.loadNode(this.node,this.resolve)
            if(this.filters.isEnable==true){
                sessionStorage.setItem('isEnable',1)
            }else if(this.filters.isEnable==false){
                sessionStorage.setItem('isEnable',2)
            }
        },
        // 新增       
        handleAdd(){
            // if(this.node.data && this.node.data.DataType == 2){
            //     return this.$message.warning('行业教材只能添加一级')
            // }
            if(this.isNotAdd){
                this.$message.warning('只能添加到三级')               
            }else{
                this.isAdd = true
                this.editDisabled = false
                this.form = {
                    sortNo:'',
                    name:'',
                    learningAmount:0,
                    remarks:'',
                    isEnable:true,
                    icon:'', 
                    isElite:true,
                    dataType:'',
                    fontColor:null,
                    dataDictionaryID:'',
                    Isappinfor: "",
                    ProjectSourceID: "",
                }
                this.tempImg = ''
                this.img = ''
                this.fileList = []
                if(this.node.level){
                    if(this.node.level == 1 && this.node.checked == true){
                        this.isGangWei = false
                    }else if(this.node.level > 1 && this.node.checked == true){
                        this.isGangWei = false
                    }
                }else{
                    this.isGangWei = true
                }
                this.dialogVisible = true
            }         
        },      
        // 编辑
        handleEdit(){ 
            this.isAdd = false
            // 设置节点是否展示相应的表单项(编辑时)
            if(this.node.level == 1 && this.node.checked == true && this.selectData.DataType != 1){
                this.isGangWei = false
            }else if(this.node.level > 1 && this.node.checked == true){
                this.isGangWei = false
            }else{
                this.isGangWei = true
            }

            if(!this.selectID){
                return this.$message.warning('请选择要编辑的一条数据')
            }else{
                this.$nextTick(() => {
                    this.form = {
                        sortNo:this.selectData.SortNo,
                        name:this.selectData.Name,
                        learningAmount:this.selectData.LearningAmount,
                        remarks:this.selectData.Remarks,
                        isEnable:this.selectData.IsEnable,
                        icon:this.selectData.IconWebImgUrl,
                        isElite:this.selectData.IsElite,
                        dataType:this.selectData.DataType,
                        fontColor:this.selectData.FontColor,
                        dataDictionaryID:this.selectData.DataDictionaryID,
                        Isappinfor: this.selectData.Isappinfor,
                        ProjectSourceID: this.selectData.ProjectSourceID,
                    }
                })
                
                this.fileList = [] // 清空之前的图片
                if(this.selectData.Icon){
                    var obj = {
                        url:this.selectData.IconWebImgUrl
                    }
                    this.fileList.push(obj)
                }else{
                    this.fileList = []
                }
                this.editDisabled = true
                this.dialogVisible = true               
            }         
        },
        // 删除
        handleDel(){
            var id = this.selectID
            if(!id){
                return this.$message.warning('请选择要删除的数据')
            }
            this.$confirm('此操作将永久删除该教材, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                var params = {id}
                deleteTeachingMaterialInfo(Qs.stringify(params)).then(res =>{
                    if(res.data.Success){
                        this.$message.success(res.data.Message)
                        this.reload()
                    }else{
                        this.$message.error(res.data.Message)
                    }
                }).catch(() =>{})
            }).catch(() =>{})
        },
        // 关联试题
        handleRelation(){
            if(!this.selectID){
                return this.$message.warning('请选择一条数据')
            }else{
                // 一级节点有子节点，不能关联试题
                if( (this.node.childNodes.length !== 0 && this.node.level == 1) || (this.node.childNodes.length !== 0 &&this.node.level == 2)){
                    this.$message.warning('该教材有子节点，请在子节点下关联试题')
                    this.relationQuestionsDialog = false
                }else{
                    this.relationQuestionsDialog = true
                    this.getQuestion()
                }
            }          
        },
        getQuestion(){
            var params = {isEnable:true,teachingMaterialID:this.selectID}
            getExaminationQuestionsByTMIDListPageAsync(params).then(res => {
                if(res.data.Success){
                    this.tableData = res.data.Response.Data
                    this.pages.dataCount = res.data.Response.DataCount
                    this.pages.pageIndex = res.data.Response.PageIndex
                    this.pages.PageSize = res.data.Response.PageSize
                }else{}
            }).catch(() => {})
        },
        // 根据教材ID获取试题集合
        getExaminationQuestionsByTMIDListPage(){
            if(!this.selectID){
                return this.$message.warning('请选择教材')
            }else{
                var params = {
                    teachingMaterialID:this.selectID,
                    rangeType:1
                }
                this.listLoading1 = true
                getExaminationQuestionsByTMIDListPageAsync(params).then(res => {
                    this.listLoading1 = false
                    if(res.data.Success){
                        this.tableData = res.data.Response.Data   
                                      
                    }else{
                        return this.$message.error(res.data.Message)
                    }
                }).catch(() =>{this.listLoading1 = false})
            }
        },
 
        // 表单提交
        submit(){
            this.$refs['formRef'].validate((valid) => {
                if (valid) {
                    var params = {...this.form}
                    if(this.selectID){
                        params.parentId = this.selectID
                    }else{
                        params.parentId = 0
                    }   /////            
                    this.formLoading = true
                    if(this.isAdd){
                        var a = this.needData.some(function(item){
                            if(item.DataType == 0){
                                return true
                            }
                        })
                        // if(this.form.dataType == 0 && a){
                        //     this.$message.warning('只能添加一本基础教材')
                        //     this.formLoading = false
                        //     this.dialogVisible = false
                        //     this.isGangWei = true
                        // }else{
                            this.addFormSubmit(params)
                        // }
                    }
                    if(!this.isAdd){
                        params.id = this.selectID
                        params.parentId = this.selectData.ParentId
                        this.node = ""
                        this.editFormSubmit(params)
                    }
                }else{return}
            })
        },
        addFormSubmit(params){
            addTeachingMaterialInfo(params).then(res =>{
                this.formLoading = false
                this.dialogVisible = false
                if(res.data.Success){
                    this.$message.success(res.data.Message)
                    if(!this.selectID){
                        this.form = {
                            sortNo:'',
                            name:'',
                            learningAmount:0,
                            remarks:'',
                            isEnable:true,
                            icon:'', //////
                            isElite:true,
                            dataType:'',
                            fontColor:''
                        }      
                        this.reload()
                    }
                    else{
                        this.refreshNodeBy(this.selectID)
                    }  
                }else{
                    this.$message.error(res.data.Message)
                    this.recordLevelCount--
                }
            }).catch(() =>{
                this.formLoading = false
                this.dialogVisible = false
            })         
        },
        editFormSubmit(params){
            updateTeachingMaterialInfo(params).then(res =>{
                if(res.data.Success){
                    this.$message.success(res.data.Message)
                    this.reload()
                }else{
                    this.$message.error(res.data.Message)
                }
            })
        },
        refreshNodeBy(id){
            var node = this.$refs.tree.getNode(id)
            node.loaded = false
            node.expand()
        },
        // 关联试题表单提交
        relationQuestionSubmit(){
            this.relationQuestionsLoading = true
            var params = {
                examinationQuestionsIDs:this.relationQuestionArr,
                teachingMaterialInfoID:this.selectID,
                treeUrl:this.treeUrl
            }
            relationTeachingExamination(params).then(res =>{
                this.relationQuestionsLoading = false
                this.relationQuestionsDialog = false
                if(res.data.Success){
                    this.$message.success(res.data.Message)
                    this.reload()
                }else{
                    this.$message.error(res.data.Message)
                }
            }).catch(() =>{
                this.relationQuestionsLoading = false
                this.relationQuestionsDialog = false
            })
        },

        // 数据类型更改
        handleIsStationChange(value){
            if(value == 1){
                this.isGangWei = true
            }else{
                this.isGangWei = false
            }
        },




        
        // 分页处理
        handleSizeChange(value){
            this.pages.pageSize = value
            this.getData()
            
        },
        handleCurrentChange(value){
            this.pages.pageIndex = value          
            this.getData()
        },
        // 查询
        handleSearch(){
            this.pages.pageIndex = 1
            this.getData()
        },
        getData(){
            var params = {
                teachingMaterialID:this.teachingMaterialInfoID,
                questionsTitle:this.filters1.questionsTitle,
                questionsType:this.filters1.questionsType,
                pageSize:this.pages.pageSize,
                pageIndex:this.pages.pageIndex,
                rangeType:this.filters1.rangeType
            }
            this.listLoading = true
            getExaminationQuestionsByTMIDListPageAsync(params).then(res =>{
                this.listLoading = false
                if(res.data.Success){
                    this.tableData = res.data.Response.Data
                    this.pages.dataCount = res.data.Response.DataCount
                    this.pages.pageIndex = res.data.Response.PageIndex
                    this.pages.PageSize = res.data.Response.PageSize
                    // // 回显选中的试题
                    // this.$nextTick(() =>{
                    //     this.tableData.forEach(row =>{
                    //         if(this.showMultipleSelection.indexOf(row.ID) >= 0){
                    //             this.$refs.multipleTable.toggleRowSelection(row,true);
                    //         }
                    //     })
                    // })
                }else{
                    this.$message.error(res.data.Message)
                }
            }).catch(() =>{
                this.listLoading = false
            })
        }
    },
    created(){
        this.action = api.uploadTempPicUrl
    },
    mounted(){
        if(sessionStorage.getItem("isEnable") == 1){
            this.filters.isEnable = true
        }else if(sessionStorage.getItem("isEnable") == 2){
            this.filters.isEnable = false
        }

        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);

    },
    beforeRouteLeave(to,from,next){
        sessionStorage.setItem('isEnable','')
        next()
    }
}
</script>

<style lang="stylus" scoped>
    /deep/ .left{
        font-size:16px;
        color:#606266;
        min-width:400px;
        max-width:450px;
    }
    /deep/ .el-tree-node{
        padding-top:3px!important;
        padding-bottom:3px!important;
    }
    /deep/ .checked{
        margin:0 5px;
    }
    /deep/ .right{
        float:left;
        min-width:55%;
        max-width:70%;
        margin-left:10px;
    }
</style>